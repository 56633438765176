// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-scholars-listing-components-index-jsx": () => import("./../src/templates/scholars/listing/components/index.jsx" /* webpackChunkName: "component---src-templates-scholars-listing-components-index-jsx" */),
  "component---src-templates-landing-components-index-jsx": () => import("./../src/templates/landing/components/index.jsx" /* webpackChunkName: "component---src-templates-landing-components-index-jsx" */),
  "component---src-templates-scholars-viewing-components-index-jsx": () => import("./../src/templates/scholars/viewing/components/index.jsx" /* webpackChunkName: "component---src-templates-scholars-viewing-components-index-jsx" */),
  "component---src-templates-scholars-comparing-components-index-jsx": () => import("./../src/templates/scholars/comparing/components/index.jsx" /* webpackChunkName: "component---src-templates-scholars-comparing-components-index-jsx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-chevening-jsx": () => import("./../src/pages/blog/chevening.jsx" /* webpackChunkName: "component---src-pages-blog-chevening-jsx" */),
  "component---src-pages-blog-marketing-jsx": () => import("./../src/pages/blog/marketing.jsx" /* webpackChunkName: "component---src-pages-blog-marketing-jsx" */),
  "component---src-pages-blog-soc-jsx": () => import("./../src/pages/blog/soc.jsx" /* webpackChunkName: "component---src-pages-blog-soc-jsx" */)
}

